import React from 'react';
import { FaWhatsapp, FaPhone, FaEnvelope } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const PaymentMethods = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handlePaymentDone = () => {
    // Redirect to home page
    navigate('/');
  };

  return (
    <div className="container mx-auto p-6 min-h-screen bg-gradient-to-r from-blue-50 to-blue-100">
      {/* New Heading */}
      <h1 className="text-3xl font-bold text-center text-blue-800 mb-6">
        Order will confirm after payment
      </h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Section: Paytm QR Code and Details */}
        <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300 transition-transform transform hover:scale-105 duration-300">
          <h2 className="text-2xl font-bold mb-4 text-blue-700">Paytm QR Code</h2>
          {/* Replace with actual QR Code */}
          <div className="flex justify-center mb-4">
            <img
              src="https://via.placeholder.com/200" // Placeholder for QR code
              alt="Paytm QR Code"
              className="w-52 h-52 border border-gray-300 rounded-lg shadow-md"
            />
          </div>
          <div className="text-gray-700">
            <p className="font-semibold text-lg">Payment Details:</p>
            <p className="mt-1">Amount: <span className="font-bold text-green-600">₹5000</span></p>
            <p>Account Name: Your Business Name</p>
            <p>Paytm Number: 1234567890</p>
            <p>Account Number: <span className="font-bold">9876543210</span></p> {/* New Account Number */}
            <p>IFSC Code: <span className="font-bold">IFSC0001234</span></p> {/* New IFSC Code */}
            <p>Receiver Address: House no. 236, Street no. 4, Mohnta Garden, Begu Road, Sirsa</p> {/* New Receiver Address */}
          </div>
          {/* Payment Done Button */}
          <div className="mt-4">
            <button
              onClick={handlePaymentDone}
              className="w-full p-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-all duration-200 shadow-md"
            >
              Payment Done
            </button>
          </div>
        </div>

        {/* Right Section: Receiver Details and Contact Options */}
        <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300 transition-transform transform hover:scale-105 duration-300">
          <h2 className="text-2xl font-bold mb-4 text-blue-700">Receiver Details</h2>
          <div className="text-gray-700 mb-4">
            <p className="font-semibold">Name: <span className="font-bold">John Doe</span></p>
            <p>Email: <span className="font-bold">john.doe@example.com</span></p>
            <p>Phone: <span className="font-bold">+91 98765 43210</span></p>
            <p>Address: House no. 236, Street no. 4, Mohnta Garden, Begu Road, Sirsa</p> {/* New Receiver Address */}
          </div>
          <h3 className="text-xl font-semibold mb-4 text-blue-600">Contact Options</h3>
          <div className="flex space-x-4">
            <a
              href="https://wa.me/919876543210" // Adjust the phone number for WhatsApp
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center p-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-all duration-200 shadow-md transform hover:scale-105"
            >
              <FaWhatsapp className="mr-2" /> Chat on WhatsApp
            </a>
            <a
              href="tel:+919876543210" // Adjust the phone number
              className="flex items-center p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all duration-200 shadow-md transform hover:scale-105"
            >
              <FaPhone className="mr-2" /> Call
            </a>
            <a
              href="mailto:john.doe@example.com" // Adjust the email
              className="flex items-center p-3 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-all duration-200 shadow-md transform hover:scale-105"
            >
              <FaEnvelope className="mr-2" /> Email
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
