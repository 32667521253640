import React from 'react';

const PriceTiersModal = ({ isOpen, onClose, priceTiers }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="bg-white p-6 rounded-lg shadow-2xl max-w-md w-full transform transition-transform duration-300 ease-in-out scale-105 hover:scale-110">
        <h2 className="text-2xl font-extrabold text-gray-800 mb-6 text-center">Price Tiers</h2>
        <table className="w-full border-collapse border border-gray-300 rounded-lg overflow-hidden shadow-md">
          <thead className="bg-gray-200">
            <tr>
              <th className="border border-gray-300 px-4 py-3 text-left text-sm font-semibold text-gray-700">Quantity</th>
              <th className="border border-gray-300 px-4 py-3 text-left text-sm font-semibold text-gray-700">Price</th>
            </tr>
          </thead>
          <tbody>
            {priceTiers.map((tier, index) => (
              <tr key={index} className="odd:bg-gray-50 even:bg-white hover:bg-gray-100 transition-colors duration-200">
                <td className="border border-gray-300 px-4 py-2 text-gray-600">{tier.quantity}</td>
                <td className="border border-gray-300 px-4 py-2 text-gray-600">₹{tier.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={onClose}
          className="mt-6 w-full bg-mainpink text-white px-4 py-3 rounded-lg shadow hover:bg-pink-700 transition-colors duration-200 ease-in-out transform hover:scale-105"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PriceTiersModal;
