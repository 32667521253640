import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "../components/Modal.js"; // Import the Modal component

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  const [loading, setLoading] = useState(false); // For showing loading state
  const [showModal, setShowModal] = useState(false); // For controlling modal visibility
  const [modalMessage, setModalMessage] = useState(""); // Modal message content
  const [isError, setIsError] = useState(false); // Flag for error modal

  const navigate = useNavigate(); // To redirect after login

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when starting the request

    try {
      // Send login data to the backend API
      const response = await axios.post(
        `https://api.payasvinimilk.com/api/user/login`,
        formData
      );

      const { token } = response.data; // Get the token from the response
      localStorage.setItem("token", token); // Save the token in localStorage

      setIsError(false); // Set error flag to false for successful login
      setModalMessage("Login successful!"); // Set modal message
      setShowModal(true); 
      console.log("Login successful:", response.data);

      // Redirect to home page after a short delay to allow user to see the modal
      setTimeout(() => {
        navigate("/"); 
      }, 1500);

    } catch (error) {
      // Handle network or other errors
      console.error("An error occurred during login:", error.message);
      setIsError(true); // Set error flag to true for errors
      setModalMessage(error.response?.data?.msg || "An error occurred during login."); // Show error message in modal
      setShowModal(true); // Display modal on error
    } finally {
      setLoading(false); // Reset loading state after request is done
    }
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  return (
    <div className="mt-20 mb-20 flex items-center justify-center">
      <div className="max-w-lg w-full mx-auto bg-white shadow-xl border-gray-300 rounded-lg p-8 md:p-12 flex flex-col">
        <h2 className="text-2xl font-extrabold text-mainpink mb-4">Login</h2>
        <hr className="border-t-2 border-lightpink mb-6" />

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-mainpink text-white py-3 rounded-lg hover:bg-pink-700 transition duration-300"
            disabled={loading} // Disable button while loading
          >
            {loading ? "Logging in..." : "Login"}
          </button>
        </form>

        <p className="text-center text-gray-600 mt-4">
          Don’t have an account?{" "}
          <Link to="/register" className="text-mainpink hover:underline">
            Create One!
          </Link>
        </p>
      </div>

      {/* Render the modal for successful login or error */}
      {showModal && (
        <Modal 
          message={modalMessage} 
          onClose={handleCloseModal} 
          isError={isError} // Pass the error flag to modal
        />
      )}
    </div>
  );
};

export default Login;
