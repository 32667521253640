import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import Card from "../components/Card"; // Import the Card component

const Home = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`https://api.payasvinimilk.com/api/banner/getBanners`); // Replace with your actual API endpoint
        // Ensure images are sorted by index
        const sortedImages = response.data.sort((a, b) => a.index - b.index);
        setImages(sortedImages);
        // console.log(sortedImages);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://api.payasvinimilk.com/api/categories/getCategories");
        setCategories(response.data);
        console.log(categories);
      } catch (err) {
        setError("Failed to fetch categories.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    const fetchProducts = async () => {
      try {
        const response = await axios.get("https://api.payasvinimilk.com/api/products/getProducts");
        setProducts(response.data);
      } catch (err) {
        setError("Failed to fetch products.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="my-2 mx-2">
      {/* Carousel */}
      <div className="min-w-full max-w-7xl mx-auto mb-8 z-0">
        <Carousel
          showThumbs={false}
          autoPlay
          infiniteLoop
          interval={3000}
          showStatus={false}
          className="overflow-hidden shadow-lg z-0"
        >
          {images.map((image) => (
            <div key={image._id}>
              <img src={image.url} alt={image.description || "Slide"} />
            </div>
          ))}
        </Carousel>
      </div>

      {/* Categories */}
      <div className="max-w-7xl mx-auto mb-8">
        <h2 className="text-2xl font-extrabold text-gray-800 mb-4">Shop by Categories</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-8">
          {categories.map((category, index) => (
            <div
                key={category.name}
                className="bg-white rounded-lg shadow-lg border-2 border-gray-200 overflow-hidden cursor-pointer"
                onClick={() => handleCategoryClick(category._id)}
              >
                <img
                  src={category.image}
                  alt={category.name}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-gray-800">
                    {category.name}
                  </h3>
                </div>
              </div>
          ))}
        </div>
      </div>

      {/* Trending Now */}
      <div className="max-w-7xl mx-auto mb-8">
        <h2 className="text-2xl font-extrabold text-gray-800 mb-4">Trending Now</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {products.map((product) => (
            <Card
            key={product._id} // Add a unique key for each product
            name={product.name}
            id={product._id}
            category={product?.category?.name || "Generic"}
            // Assuming category is populated
            price={product.priceTiers[0].price}
            quantity={product.priceTiers[0].quantity}
            image={product.images[0]} // Display the first image
          />
          ))}
        </div>
      </div>

      {/* Other sections like Customer Favorites, Top Picks can be added similarly */}
    </div>
  );
};

export default Home;
