import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Card from "../components/Card"; // Adjust the import path according to your folder structure

const ViewCategories = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const categoryResponse = await axios.get(
          `https://api.payasvinimilk.com/api/categories/getCategoryById/${category}`
        );
        setCategoryDetails(categoryResponse.data);

        // Fetch products of the category
        const productsResponse = await axios.get(
          `https://api.payasvinimilk.com/api/products/products/category/${category}` // Adjusted API endpoint to match the one created earlier
        );
        setProducts(productsResponse.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load category details or products.");
        setLoading(false);
      }
    };

    fetchCategoryDetails();
  }, [category]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!categoryDetails) return <p>Category not found.</p>;

  const { name, image, subcategory } = categoryDetails;

  const handleSubcategoryClick = (subcatId, subcatName) => {
    navigate(`/subcategory/${subcatId}/${subcatName}`);
  };

  return (
    <div className="my-2">
      <div className="mb-8">
        <img
          src={image}
          alt={`${name} Banner`}
          className="min-w-full h-64 object-cover shadow-lg"
        />
      </div>
      <div className="max-w-7xl mx-auto p-4">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">{name}</h2>
        
        {subcategory.length > 0 && (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
            {subcategory.map((subcat) => (
              <div
                key={subcat.name}
                className="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer"
                onClick={() => handleSubcategoryClick(subcat._id, subcat.name)}
              >
                <img
                  src={subcat.imgPath}
                  alt={subcat.name}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-gray-800">
                    {subcat.name}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Display products if no subcategories */}
        {subcategory.length === 0 && (
          <>
            <h3 className="text-xl font-bold text-gray-800 mb-4">Products</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {products.length > 0 ? (
                products.map((product) => (
                  <Card
                    key={product._id}
                    id={product._id}
                    name={product.name}
                    category={product.category.name} // Assuming category has a name property
                    price={product.priceTiers[0].price} 
                    quantity={product.priceTiers[0].quantity}
                    image={product.images[0]} // Assuming images is an array and you want the first image
                  />
                ))
              ) : (
                <p className="text-gray-600">No products available in this category.</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ViewCategories;