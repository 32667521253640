import React, { useState, useEffect } from "react"; 
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "../components/Card"; // Import your Card component

const PAGE_SIZE = 10;

const SearchSection = () => {
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sortOption, setSortOption] = useState(""); // State for sorting
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://api.payasvinimilk.com/api/products/getProducts`);
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch products");
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`https://api.payasvinimilk.com/api/categories/getCategories`);
        setCategories(response.data);
      } catch (error) {
        setError("Failed to fetch categories");
      }
    };

    fetchProducts();
    fetchCategories();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("query") || "";
    filterProducts(query, selectedCategory, sortOption);
  }, [location.search, products, selectedCategory, sortOption, currentPage]);

  const filterProducts = (query, category, sortOrder) => {
    if (products.length !== 0) {
      let filteredProducts = [...products]; // Ensure to copy array

      // Filter by category if specified
      if (category && category !== "All Categories") {
        filteredProducts = filteredProducts.filter((product) =>
          product.category.name === category
        );
      }

      // Apply other filters based on the query
      filteredProducts = filteredProducts.filter((product) => {
        return !query || product.name.toLowerCase().includes(query.toLowerCase());
      });

      // Sort products based on the selected sort order
      const sortedProducts = [...filteredProducts].sort((a, b) => {
        switch (sortOrder) {
          case "priceLowToHigh":
            return a.price - b.price;
          case "priceHighToLow":
            return b.price - a.price;
          case "popularity":
            return b.salesCount - a.salesCount; // Assuming products have a salesCount property
          case "newest":
            return new Date(b.createdAt) - new Date(a.createdAt); // Assuming products have a createdAt date
          case "oldest":
            return new Date(a.createdAt) - new Date(b.createdAt); // Assuming products have a createdAt date
          default:
            return 0;
        }
      });

      const startIndex = (currentPage - 1) * PAGE_SIZE;
      const endIndex = startIndex + PAGE_SIZE;
      setDisplayedProducts(sortedProducts.slice(startIndex, endIndex));
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setCurrentPage(1); // Reset to the first page when the category changes
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    setCurrentPage(1); // Reset to the first page when the sort option changes
  };

  const totalPages = Math.ceil(products.filter(product =>
    product.name.toLowerCase().includes((new URLSearchParams(location.search).get("query") || "").toLowerCase())
    && (selectedCategory ? product.category.name === selectedCategory : true)
  ).length / PAGE_SIZE);

  if (loading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-4 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4">
        <label htmlFor="category" className="block text-gray-700">Search by Category:</label>
        <select
          id="category"
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="">All Categories</option>
          {categories.map((category) => (
            <option key={category._id} value={category.name}>{category.name}</option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label htmlFor="sort" className="block text-gray-700">Sort by:</label>
        <select
          id="sort"
          value={sortOption}
          onChange={handleSortChange}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="">Select an option</option>
          <option value="popularity">Popularity</option>
          <option value="priceLowToHigh">Price: Low to High</option>
          <option value="priceHighToLow">Price: High to Low</option>
          <option value="newest">Newest First</option>
          <option value="oldest">Oldest First</option>
        </select>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {displayedProducts.map((product) => (
          <Card
            key={product._id}
            name={product.name}
            id={product._id}
            category={product.category.name}
            price={product.priceTiers[0].price}
            image={product.images[0]} // Display the first image
            onClick={() => navigate(`/product/${product._id}`)} // Navigate to product detail on click
          />
        ))}
      </div>

      <div className="flex justify-center mt-4">
        <button
          className="px-4 py-2 border rounded-lg mr-2 bg-blue-500 text-white hover:bg-blue-600"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <span className="self-center">{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          className="px-4 py-2 border rounded-lg ml-2 bg-blue-500 text-white hover:bg-blue-600"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SearchSection;
