import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "./CartContext"; // Import CartContext
import { useState } from "react";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { cartItems } = useContext(CartContext); // Get cart items from context
  const history = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      history(`/search?query=${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <nav className="bg-white text-mainpink">
      <div className="flex flex-row justify-between items-center py-4 px-6 md:px-36">
        <div className="flex items-center text-2xl font-semibold text-mainpink">
          <Link to="/">
            <img
              src="https://ik.imagekit.io/7uve7qsipm/logo%20after%20effect.png?updatedAt=1724158768789"
              className="w-36 md:w-48"
              alt="Logo"
            />
          </Link>
        </div>

        {/* Search bar */}
        <div className="hidden md:flex items-center mt-4 md:mt-0 md:w-1/3 justify-center">
          <form onSubmit={handleSearch} className="flex w-full">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full md:w-2/3 px-4 py-2 rounded-l-md bg-pink-100 text-mainpink focus:outline-none focus:ring focus:ring-pink-300"
            />
            <button type="submit" className="px-4 py-2 bg-mainpink text-white rounded-r-md hover:bg-pink-700">
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </form>
        </div>

        {/* Account and Cart icons */}
        <div className="flex gap-4 md:mt-0 text-xl">
          <Link to="/account" className="hover:text-pink-700">
            <i className="fa-solid fa-user"></i>
          </Link>
          <Link to="/cart" className="hover:text-pink-700 relative">
            <i className="fa-solid fa-bag-shopping"></i>
            {cartItems.length > 0 && (
              <span className="absolute top-0 right-0 rounded-full bg-red-500 text-white text-xs w-5 h-5 flex items-center justify-center">
                {cartItems.length}
              </span>
            )}
          </Link>

          {/* Hamburger icon for mobile menu */}
          <button className="md:hidden text-2xl focus:outline-none" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
      </div>

      {/* Bottom Navbar */}
      <div className={`bg-mainblue ${isMenuOpen ? "block" : "hidden"} md:block`}>
        <ul className="flex flex-col md:flex-row justify-center text-center gap-8 py-3 text-white font-semibold">
        <li className="hover:text-pink-800">
            <Link to="/">Home</Link>
          </li>
          <li className="hover:text-pink-800">
            <Link to="/designing-work">Designing Work</Link>
          </li>
          <li className="hover:text-pink-700">
            <Link to="/logo-designing">Logo Designing</Link>
          </li>
          <li className="hover:text-pink-700">
            <Link to="/website-designing">Website Designing</Link>
          </li>
          <li className="hover:text-pink-700">
            <Link to="/applyDistributionship">Distribuitionship</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
