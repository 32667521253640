import React, { useState } from 'react';

const DistributionForm = () => {
  const [formData, setFormData] = useState({
    businessName: '',
    contactPerson: '',
    country: '',
    state: '',
    city: '',
    pinCode: '',
    gstNumber: '',
    gstinScheme: '',
    shippingAddress: '',
    mobile: '',
    email: '',
    password: '',
    status: 'inactive'
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://api.payasvinimilk.com/api/distribution/apply', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
       
        alert('Application submitted successfully!');
      } else {
        alert(data.message || 'Failed to submit application.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert(error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg my-8">
      <h2 className="text-2xl font-bold mb-6 text-center text-[#e50b80]">Apply For Distributorship</h2>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Business Name</label>
          <input
            type="text"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02A0E3]"
            placeholder="Your Business Name"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Contact Person</label>
          <input
            type="text"
            name="contactPerson"
            value={formData.contactPerson}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02A0E3]"
            placeholder="Contact Person's Name"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Country</label>
          <input
            type="text"
            name="country"
            value={formData.country}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02A0E3]"
            placeholder="Your Country"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">State</label>
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02A0E3]"
            placeholder="Your State"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">City</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02A0E3]"
            placeholder="Your City"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Pin Code</label>
          <input
            type="text"
            name="pinCode"
            value={formData.pinCode}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02A0E3]"
            placeholder="Your Area Pin Code"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">GST Number</label>
          <input
            type="text"
            name="gstNumber"
            value={formData.gstNumber}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02A0E3]"
            placeholder="Your GST Number"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">GSTin Scheme</label>
          <input
            type="text"
            name="gstinScheme"
            value={formData.gstinScheme}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02A0E3]"
            placeholder="Your GSTin Scheme"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Shipping Address</label>
          <textarea
            name="shippingAddress"
            value={formData.shippingAddress}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02A0E3]"
            placeholder="Your Shipping Address"
            rows="3"
          ></textarea>
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Mobile</label>
          <input
            type="tel"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02A0E3]"
            placeholder="Your Mobile Number"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02A0E3]"
            placeholder="Your Email Address"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#02A0E3]"
            placeholder="Create a Password"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-[#e50b80] text-white py-2 rounded-md hover:bg-[#f394c7] transition-colors duration-200"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default DistributionForm;
