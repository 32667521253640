import React from "react";

const TermCondition = () => {
  return (
    <div className="bg-pink-10 mt-20 mb-20 flex items-center justify-center">
      <div className="max-w-3xl mx-auto bg-white shadow-xl border-gray-300 rounded-lg p-8 md:p-12">
        <h1 className="text-2xl font-extrabold text-mainpink mb-4 text-center">
          Terms and Conditions
        </h1>
        <hr className="border-t-2 border-lightpink mb-6" />
        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Use of Our Site
        </h2>
        <p className="text-base text-gray-700 leading-relaxed mb-4">
          You may use our site only for lawful purposes and in accordance with
          these Terms. You must not use our site in any way that violates any
          applicable local, national, or international law or regulation.
        </p>

        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Intellectual Property
        </h2>
        <p className="text-base text-gray-700 leading-relaxed mb-4">
          All content and materials on our site are the intellectual property of
          Sasta Print or its licensors. You may not reproduce, distribute, or
          create derivative works from any content without prior written
          permission.
        </p>

        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Limitation of Liability
        </h2>
        <p className="text-base text-gray-700 leading-relaxed mb-4">
          To the fullest extent permitted by law, Sasta Print will not be liable
          for any indirect, incidental, special, or consequential damages
          arising from the use or inability to use our site.
        </p>

        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Changes to These Terms
        </h2>
        <p className="text-base text-gray-700 leading-relaxed mb-4">
          We may update these Terms from time to time. We will notify you of any
          changes by posting the new Terms on our site. Please review these Terms
          periodically for any changes.
        </p>
      </div>
    </div>
  );
};

export default TermCondition;
