import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Details = () => {
  const [formData, setFormData] = useState({
    name: '',
    businessName: '',
    mobileNo: '',
    email: '',
    webAddress: '',
    postalAddress: '',
    otherDetails: '',
  });
const Navigate = useNavigate();
  // Load existing data from localStorage when the component mounts
  useEffect(() => {
    const storedData = localStorage.getItem('userdetails');
    if (storedData) {
      setFormData(JSON.parse(storedData));
    }
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Save the form data to localStorage
    localStorage.setItem('userdetails', JSON.stringify(formData));
    alert('Details saved successfully!');
    Navigate('/checkout');
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg my-8">
      <h2 className="text-2xl font-bold mb-6 text-center text-mainpink">Contact Information</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            placeholder="Your Name"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Business Name</label>
          <input
            type="text"
            name="businessName"
            value={formData.businessName}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            placeholder="Your Business Name"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Mobile No</label>
          <input
            type="tel"
            name="mobileNo"
            value={formData.mobileNo}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            placeholder="Your Mobile Number"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            placeholder="Your Email Address"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Web Address</label>
          <input
            type="url"
            name="webAddress"
            value={formData.webAddress}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            placeholder="Your Website"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Postal Address</label>
          <textarea
            name="postalAddress"
            value={formData.postalAddress}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            placeholder="Your Postal Address"
            rows="3"
          ></textarea>
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Other Details</label>
          <textarea
            name="otherDetails"
            value={formData.otherDetails}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            placeholder="Any Other Details"
            rows="3"
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full bg-mainpink text-white py-2 rounded-md hover:bg-pink-700 transition-colors duration-200"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Details;
