import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* Additional div for "Still can't find what you're looking for?" */}
      <div className="bg-gray-200 text-gray-800 py-4 px-6 text-center">
        <p className="text-lg font-medium">Still can’t find what you’re looking for / Bulk Orders / Customized Orders ?</p>
        <p className="text-sm mt-2">
          Call us at{" "}
          <a
            href="tel:9536363591"
            className=" hover:underline hover:text-blue-800"
          >
            95363 63591
          </a>
        </p>
      </div>
    <footer className="bg-black text-white py-8  bottom-0 left-0 w-full">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start space-y-8 md:space-y-0 md:space-x-12 px-6">
        <div className="about max-w-sm">
          <h1 className="text-xl font-semibold mb-4">About Us</h1>
          <p className="text-gray-400 leading-relaxed">
            Sasta Print is a new initiative to provide better service in the
            area of designing, printing, and advertisements. We are committed
            to offering all of your printing needs at economical prices. A
            complete design-print-advertisement shop with a single motto: to
            provide the best service at the best prices.
          </p>
        </div>
        <div className="help">
          <h1 className="text-xl font-semibold mb-4">Let Us Help</h1>
          <ul className="space-y-2">
            <li><Link to="/my-account" className="hover:underline">My Account</Link></li>
            <li><Link to="/contact" className="hover:underline">Contact Us</Link></li>
            <li><Link to="/products" className="hover:underline">All Products</Link></li>
          </ul>
        </div>
        <div className="company">
          <h1 className="text-xl font-semibold mb-4">Our Company</h1>
          <ul className="space-y-2">
            <li><Link to="/about" className="hover:underline">About Us</Link></li>
          </ul>
        </div>
        <div className="ourpolicies">
          <h1 className="text-xl font-semibold mb-4">Our Policies</h1>
          <ul className="space-y-2">
            <li><Link to="/terms" className="hover:underline">Terms and Conditions</Link></li>
            <li><Link to="/copyright" className="hover:underline">Copyright Matters</Link></li>
            <li><Link to="/privacy-policy" className="hover:underline">Privacy Policy</Link></li>
          </ul>
        </div>
      </div>
      <div className="text-center py-4 border-t border-gray-700 mt-8">
        <p className="text-gray-400">A Soft Coders Product ©2024. All rights reserved.</p>
      </div>
    </footer>
    </>
  );
};

export default Footer;
