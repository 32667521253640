import React from 'react';

const About = () => {
  return (
    <div className="bg-pink-10 mt-20 mb-20 flex items-center justify-center">
      <div className="max-w-3xl mx-auto bg-white shadow-xl border-lightpink rounded-lg p-8 md:p-12">
        <h1 className="text-3xl font-extrabold text-mainpink mb-4 text-center">About Us</h1>
        <hr className="border-t-2 border-lightpink mb-6" />
        <p className="text-lg text-gray-700 leading-relaxed text-justify">
          <span className="font-semibold">Sasta Print</span> is a new initiative to provide better service in the
          area of designing, printing, and advertisements. We are committed to offering all of your printing needs
          at economical prices. A complete design-print-advertisement shop with a single motto: to provide the best
          service at the best prices.
        </p>
      </div>
    </div>
  );
};

export default About;
