import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-pink-10 mt-20 mb-20 flex items-center justify-center">
      <div className="max-w-3xl mx-auto bg-white shadow-xl border-gray-300 rounded-lg p-8 md:p-12">
        <h1 className="text-2xl font-extrabold text-mainpink mb-4 text-center">
          Privacy Policy
        </h1>
        <hr className="border-t-2 border-lightpink mb-6" />

        <p className="text-base text-gray-700 leading-relaxed mb-4">
          <strong>Sasta Print</strong> is committed to protecting your privacy.
          This Privacy Policy explains how we collect and use your information
          when you visit our website.
        </p>

        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Information We Collect
        </h2>
        <p className="text-base text-gray-700 leading-relaxed mb-4">
          We collect personal data you provide and usage data about your
          interaction with our Site.
        </p>

        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          How We Use Your Information
        </h2>
        <p className="text-base text-gray-700 leading-relaxed mb-4">
          We use your information to process orders, improve our services, and
          communicate with you.
        </p>

        <h2 className="text-xl font-semibold text-gray-800 mb-2">Cookies</h2>
        <p className="text-base text-gray-700 leading-relaxed mb-4">
          We use cookies to enhance your experience on our Site. You can manage
          your cookie preferences through your browser settings.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
