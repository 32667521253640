import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"; // For generating unique IDs
import PriceTiersModal from "../components/PriceTiersModal";
const ViewProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState("");
  const [customQuantity, setCustomQuantity] = useState("100");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [dieShapes, setDieShapes] = useState([]); // State to store die shapes
  const [selectedDieShape, setSelectedDieShape] = useState(""); // State for selected die shape
  const [selectedPaperType, setSelectedPaperType] = useState("");
  const [selectedFlapOpening, setSelectedFlapOpening] = useState("");
  const [selectedWindowCutting, setSelectedWindowCutting] = useState("");
  const [selectedPrinting, setSelectedPrinting] = useState("");
  const [selectedLaminationType, setSelectedLaminationType] = useState("");
  const navigate = useNavigate();
  const dieShapeImageUrl =
    "https://ik.imagekit.io/7uve7qsipm/images_product-images_159_DIE%20SHAPES%20ALL.jpg?updatedAt=1729937733711";
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `https://api.payasvinimilk.com/api/products/getProductById/${id}`
        );
        setProduct(response.data);
        console.log(response.data);
        setSelectedImage(response.data.images[0]); // Set the first image as the default selected image
      } catch (error) {
        setError("Failed to fetch product data.");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);
  const handleUploadDesign = () => {
    navigate("/upload-design");
  };

  const handleCustomizeDesign = () => {
    navigate("/customize-design");
  };
  // Fetch die shapes from the API
  useEffect(() => {
    const fetchDieShapes = async () => {
      try {
        const response = await axios.get(
          "https://api.payasvinimilk.com/api/dieshapes"
        );
        setDieShapes(response.data); // Assuming the API returns an array of die shapes
        //setSelectedDieShape(response.data); // Set default die shape if available
      } catch (error) {
        console.error("Failed to fetch die shapes.", error);
      }
    };

    fetchDieShapes();
  }, []);
  const extractYouTubeID = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|embed\/|watch\?v=|watch\?.+&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleQuantityChange = (e) => {
    const selectedQty = Number(e.target.value);
    setQuantity(selectedQty);
    setCustomQuantity(selectedQty);
  };

  const handleCustomQuantityChange = (e) => {
    const qty = Number(e.target.value);
    if (qty >= 1) {
      setQuantity(qty);
      setCustomQuantity(qty);
    }
  };

  const getPrice = (quantity) => {
    if (!product || !product.priceTiers || product.priceTiers.length === 0)
      return 0;

    const tiers = product.priceTiers;

    // Find the largest quantity in the tiers that is less than or equal to the provided quantity
    for (let i = tiers.length - 1; i >= 0; i--) {
      if (quantity >= tiers[i].quantity) {
        return tiers[i].price;
      }
    }

    // If no tier matches, return 0 as a fallback
    return 0;
  };
  const handleOpenImageModal = () => {
    setIsImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  const handleAddToCart = () => {
    // Check if quantity is selected
    if (!quantity) {
      alert("Quantity is required.");
      return;
    }

    // Check if required fields (die shape, paper type, flap opening, window cutting) are selected
    if (
      (product.isDieShape && !selectedDieShape) ||
      (product.isPaperType && !selectedPaperType) ||
      (product.isFlapOpening && !selectedFlapOpening) ||
      (product.isWindowCutting && !selectedWindowCutting) ||
      (product.isPrinting && !selectedPrinting) ||
      (product.isLaminationType && !selectedLaminationType)
    ) {
      // Show an alert specifying which fields are missing
      let missingFields = [];
      if (product.isDieShape && !selectedDieShape)
        missingFields.push("Die Shape");
      if (product.isPaperType && !selectedPaperType)
        missingFields.push("Paper Type");
      if (product.isFlapOpening && !selectedFlapOpening)
        missingFields.push("Flap Opening");
      if (product.isWindowCutting && !selectedWindowCutting)
        missingFields.push("Window Cutting");
      if (product.isPrinting && !selectedPrinting)
        missingFields.push("Printing");
      if (product.isLaminationType && !selectedLaminationType)
        missingFields.push("Lamination Type"); // Add selected lamination type to cart item if available

      alert(`Please select the following fields: ${missingFields.join(", ")}`);
      return;
    }
    const uniqueId = uuidv4(); // Generate a unique identifier for the cart item
    const cartItem = {
      id: uniqueId,
      productId: id,
      productName: product.name,
      productImage: selectedImage, // Use the selected image
      quantity,
      price: getPrice(quantity),
      totalPrice: (getPrice(quantity) * quantity).toFixed(2),
      dieShape: selectedDieShape, // Add selected die shape to cart item
      paperType: selectedPaperType,
      flapOpening: selectedFlapOpening,
      windowCutting: selectedWindowCutting,
      printing: selectedPrinting,
      laminationType: selectedLaminationType,
    };

    console.log("Selected Die Shape:", selectedDieShape); // Log to verify

    // Retrieve the current cart from localStorage or initialize it as an empty array
    const currentCart = JSON.parse(localStorage.getItem("cart")) || [];

    // Add the new cart item to the cart array
    currentCart.push(cartItem);

    // Save the updated cart back to localStorage
    localStorage.setItem("cart", JSON.stringify(currentCart));

    alert("Product added to cart!");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!product) {
    return <div>No product data available.</div>;
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="flex flex-col md:flex-row gap-4">
        {/* Left Section: Product Images */}
        <div className="md:w-1/2 flex flex-col">
          <div className="mb-4">
            {/* Conditionally render the YouTube video player if the videoUrl exists */}
            {product.videoUrl ? (
              <iframe
                width="100%"
                height="350"
                src={`${product.videoUrl}?`} // YouTube autoplay with mute
                title="Product Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg shadow-md"
              ></iframe>
            ) : (
              <img
                src={selectedImage}
                alt="Product"
                className="w-full h-80 object-cover rounded-lg shadow-md"
              />
            )}
          </div>

          <div className="flex overflow-x-auto space-x-2">
            {/* Conditionally render the thumbnail for the video */}
            {product.videoUrl && (
              <div
                className="flex-shrink-0 w-24 h-24 cursor-pointer"
                onClick={() =>
                  setSelectedImage(
                    `https://img.youtube.com/vi/${extractYouTubeID(
                      product.videoUrl
                    )}/hqdefault.jpg`
                  )
                } // Set the video thumbnail
              >
                <img
                  src={`https://img.youtube.com/vi/${extractYouTubeID(
                    product.videoUrl
                  )}/hqdefault.jpg`}
                  alt="Video Thumbnail"
                  className="w-full h-full object-cover border rounded-lg shadow-md"
                />
              </div>
            )}

            {/* Render the other product images */}
            {product.images.map((image, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-24 h-24 cursor-pointer"
                onClick={() => setSelectedImage(image)} // Update selected image on click
              >
                <img
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  className="w-full h-full object-cover border rounded-lg shadow-md"
                />
              </div>
            ))}
          </div>
        </div>

        {/* Right Section: Product Details */}
        <div className="md:w-1/2 md:pl-6">
          <h1 className="text-3xl font-bold mb-2">{product.name}</h1>
          <div className="mt-2">
            <p className="text-gray-700">
              {product.description.split(",").map((item, index) => (
                <span
                  key={index}
                  className={`block ${index > 0 ? "mt-2" : ""}`}
                >
                  {index === 0 && (
                    <i className="fa-solid fa-circle-info mr-2"></i>
                  )}
                  {item.trim()}
                </span>
              ))}
            </p>
          </div>

          <h1 className="my-3 font-semibold text-mainpink">
            <i class="fa-solid fa-truck"></i> Delivery Estimate :{" "}
            {product.expectedDeliveryTime}
          </h1>
          {/* Die Shape Dropdown */}
          <div className="mb-4">
            {/* Conditionally render the die shape dropdown based on isDieShape */}
            {product.isDieShape && (
              <>
                <h2 className="text-xl font-semibold my-2">Select Die Shape</h2>
                <select
                  value={selectedDieShape}
                  onChange={(e) => setSelectedDieShape(e.target.value)}
                  className="border rounded-lg py-2 px-4 w-full"
                  required
                >
                  <option value="">Select Die Shape</option>
                  {dieShapes.map((shape) => (
                    <option key={shape._id} value={shape.name}>
                      {shape.name} {/* Use the `name` property to display */}
                    </option>
                  ))}
                </select>
                <div className="mb-4">
                  {/* Simple button to open the image modal */}
                  <button
                    onClick={handleOpenImageModal}
                    className="bg-mainblue z-[99] text-white py-2 px-4 rounded-lg hover:bg-blue-700 my-2"
                  >
                    See Die Shapes Options
                  </button>

                  {/* Image Modal */}
                  {isImageModalOpen && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
                      <div className="bg-white rounded-lg p-6 shadow-lg max-w-6xl w-full">
                        <h3 className="text-lg font-semibold mb-4 text-center">
                          Die Shape Image
                        </h3>
                        {/* Display the fixed image with a smaller size */}
                        <img
                          src={dieShapeImageUrl}
                          alt="Die Shape"
                          className="mb-4 mx-auto  object-contain"
                        />
                        <button
                          onClick={handleCloseImageModal}
                          className="bg-red-500 hover:bg-red-600 r-0 text-white py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-400"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            <h2 className="text-xl font-semibold mb-2">
              Select {product.isPaperType ? "Details" : "Quantity"}
            </h2>
            {/* Paper Type Dropdown */}
            {product.isPaperType && (
              <div className="mb-4">
                <select
                  value={selectedPaperType}
                  onChange={(e) => setSelectedPaperType(e.target.value)}
                  className="border rounded-lg py-2 px-4 w-full"
                >
                  <option value="">Select Paper Type</option>
                  {product.paperTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Flap Opening Dropdown */}
            {product.isFlapOpening && (
              <div className="mb-4">
                <select
                  value={selectedFlapOpening}
                  onChange={(e) => setSelectedFlapOpening(e.target.value)}
                  className="border rounded-lg py-2 px-4 w-full"
                >
                  <option value="">Select Flap Opening</option>
                  {product.flapOpenings.map((opening) => (
                    <option key={opening} value={opening}>
                      {opening}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Window Cutting Dropdown */}
            {product.isWindowCutting && (
              <div className="mb-4">
                <select
                  value={selectedWindowCutting}
                  onChange={(e) => setSelectedWindowCutting(e.target.value)}
                  className="border rounded-lg py-2 px-4 w-full"
                >
                  <option value="">Select Window Cutting</option>
                  {product.windowCuttings.map((cutting) => (
                    <option key={cutting} value={cutting}>
                      {cutting}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* printing Dropdown */}
            {product.isPrinting && (
              <div className="mb-4">
                <select
                  value={selectedPrinting}
                  onChange={(e) => setSelectedPrinting(e.target.value)}
                  className="border rounded-lg py-2 px-4 w-full"
                >
                  <option value="">Select Printing</option>
                  {product.printings.map((printing) => (
                    <option key={printing} value={printing}>
                      {printing}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Lamination Type Dropdown */}
            {product.isLaminationType && (
              <div className="mb-4">
                <select
                  value={selectedLaminationType}
                  onChange={(e) => setSelectedLaminationType(e.target.value)}
                  className="border rounded-lg py-2 px-4 w-full"
                >
                  <option value="">Select Lamination Type</option>
                  {product.laminationTypes.map((lamination) => (
                    <option key={lamination} value={lamination}>
                      {lamination}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>

          {/* Quantity and Price */}
          <div className="mb-4">
            <select
              value={quantity}
              onChange={handleQuantityChange}
              className="border rounded-lg py-2 px-4 w-full"
            >
              <option value="">Select Quantity</option>
              {product.priceTiers.map((tier) => (
                <option key={tier._id} value={tier.quantity}>
                  {tier.quantity} {/* Use the `quantity` property to display */}
                </option>
              ))}
            </select>

            <p className="mt-2 text-lg font-bold">
              Price: ₹{getPrice(quantity)} per print
            </p>
            <p className="text-gray-500">
              Total Price: ₹{(getPrice(quantity) * quantity).toFixed(2)}
            </p>

            {/* Added Designing Free Message */}
            <div className="mt-4 bg-red-100 p-4 rounded-lg text-gray-700 text-sm">
              <p>
                <span className="font-bold">Designing Free:</span> After placing
                the order, our executive will contact you on WhatsApp regarding
                your designing needs.
              </p>
            </div>
          </div>

          {/* Add to Cart Button */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <button
              className="bg-mainblue text-white py-2 px-2 rounded-lg hover:bg-blue-700"
              onClick={handleAddToCart}
            >
              Add to Cart
            </button>

            <button
              onClick={handleOpenModal}
              className="bg-mainpink text-white py-2 px-2 rounded-lg hover:bg-pink-700"
            >
              What We Charge
            </button>

            <button
              onClick={handleUploadDesign}
              className="bg-mainpink text-white py-2 px-2 rounded-lg hover:bg-pink-700"
            >
              Upload Design
            </button>

            <button
              onClick={handleCustomizeDesign}
              className="bg-mainpink text-white py-2 px-2 rounded-lg hover:bg-pink-700"
            >
              Customize Design
            </button>
          </div>
          <PriceTiersModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            priceTiers={product.priceTiers}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
