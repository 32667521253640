import { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { FiPhoneCall } from 'react-icons/fi';
import AOS from 'aos';

const WhatsAppFormModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* WhatsApp Icon Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-green-600 p-4 rounded-full shadow-lg text-white hover:bg-green-700 transition duration-300 transform hover:scale-105"
        data-aos="zoom-in"
      >
        <FaWhatsapp size={24} />
      </button>

      {/* Modal */}
      {isOpen && (
        <div
          className="fixed bottom-0 right-0 bg-gradient-to-t from-white to-gray-100 w-11/12 h-3/4 md:w-96 md:h-auto p-6 rounded-tl-lg rounded-tr-lg shadow-lg transition transform duration-300"
          data-aos="fade-up"
          style={{ zIndex: 1000 }} // Ensure the modal is on top of other elements
        >
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-bold text-gray-800">Contact Us</h3>
            <button
              onClick={() => setIsOpen(false)}
              className="bg-gray-300 rounded-full p-1 hover:bg-gray-400 transition duration-200"
              aria-label="Close"
            >
              <span className="text-gray-800 text-xl">&times;</span>
            </button>
          </div>

          {/* Form Fields */}
          <form className="mt-4">
            <div className="mb-4">
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 transition duration-200 bg-white shadow-sm"
                placeholder="Your Name"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 transition duration-200 bg-white shadow-sm"
                placeholder="Your Email"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Message</label>
              <textarea
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 transition duration-200 bg-white shadow-sm"
                placeholder="Your Message"
                rows="3"
              ></textarea>
            </div>

            {/* Continue to WhatsApp */}
            <button
              type="button"
              className="w-full bg-green-600 text-white p-3 rounded-lg flex items-center justify-center hover:bg-green-700 transition duration-300 mb-2 transform hover:scale-105 shadow-md"
              onClick={() =>
                window.open('https://wa.me/9536363591', '_blank')
              }
              data-aos="fade-right"
            >
              <FaWhatsapp className="mr-2" />
              Continue to WhatsApp
            </button>

            {/* Call Now Option */}
            <button
              type="button"
              className="w-full bg-blue-600 text-white p-3 rounded-lg flex items-center justify-center hover:bg-blue-700 transition duration-300 transform hover:scale-105 shadow-md"
              onClick={() => window.open('tel:9536363591')}
              data-aos="fade-left"
            >
              <FiPhoneCall className="mr-2" />
              Call Now
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default WhatsAppFormModal;
