import React from "react";

const Modal = ({ message, onClose, isError }) => {
  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50 transition-opacity duration-300">
      <div className={`relative bg-white rounded-2xl p-8 w-96 shadow-lg text-center transform transition-all duration-300 ${isError ? 'bg-red-50 border border-red-500 text-red-700' : 'bg-green-50 border border-green-500 text-green-700'}`}>
        
        <h2 className={`text-2xl font-extrabold mb-4 ${isError ? 'text-red-700' : 'text-green-700'}`}>
          {isError ? "Error" : "Success"}
        </h2>
        
        <p className="text-lg mb-6 font-medium">
          {message}
        </p>
        
        <button
          className={`inline-block w-full py-3 rounded-xl text-lg font-bold tracking-wide shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-300 ${isError ? 'bg-red-600 text-white hover:bg-red-700 focus:ring-red-500' : 'bg-green-600 text-white hover:bg-green-700 focus:ring-green-500'}`}
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
