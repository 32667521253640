import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., send formData to an API)
    console.log("Form submitted:", formData);
  };

  return (
    <div className="bg-pink-10 mt-20 mb-20 flex items-center justify-center">
      <div className="max-w-5xl w-full mx-auto bg-white shadow-xl border-gray-300 rounded-lg p-8 md:p-12 flex flex-col md:flex-row">
        
        {/* Left Section - Contact Information */}
        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
          <h2 className="text-2xl font-extrabold text-mainpink mb-4">
            Contact Information
          </h2>
          <hr className="border-t-2 border-lightpink mb-6" />

          <p className="text-gray-700 mb-4">
            <strong>Mailing Address:</strong> 1234 Street Name, City, Country
          </p>
          <p className="text-gray-700 mb-4">
            <strong>Phone:</strong> +1 234 567 890
          </p>
          <p className="text-gray-700 mb-6">
            <strong>WhatsApp:</strong> +1 234 567 890 (Click to <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer" className="text-mainpink hover:underline">Chat on WhatsApp</a>)
          </p>

          <p className="text-base text-gray-700 leading-relaxed">
            If you have any questions or need assistance, feel free to reach out to us through the provided contact details or fill out the form.
          </p>
        </div>

        {/* Right Section - Contact Form */}
        <div className="md:w-1/2">
          <h2 className="text-2xl font-extrabold text-mainpink mb-4">
            Contact Us
          </h2>
          <hr className="border-t-2 border-lightpink mb-6" />

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-gray-700 mb-1">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>
            
            <div>
              <label htmlFor="email" className="block text-gray-700 mb-1">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>
            
            <div>
              <label htmlFor="message" className="block text-gray-700 mb-1">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>
            
            <button
              type="submit"
              className="w-full bg-mainpink text-white py-3 rounded-lg hover:bg-pink-700 transition duration-300"
            >
              Send Message
            </button>
          </form>
        </div>

      </div>
    </div>
  );
};

export default Contact;
