import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const CategoryNavbar = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.payasvinimilk.com/api/categories/getCategories")
      .then((response) => setCategories(response.data))
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  const handleCategoryHover = (category) => {
    setSelectedCategory(category._id);
    setSubcategories(category.subcategory || []);
  };

  const handleMouseLeave = (e) => {
    if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
      setSelectedCategory(null);
      setSubcategories([]);
    }
  };

  return (
    <div
      className="bg-mainblue z-[99] py-3 text-white font-semibold w-full relative"
      onMouseLeave={handleMouseLeave}
    >
      <ul className="flex flex-col md:flex-row justify-center text-center gap-8">
        {categories.slice(0, 5).map((category) => (
          <li
            key={category._id}
            className="cursor-pointer relative"
            onMouseEnter={() => handleCategoryHover(category)}
          >
            <button className="hover:text-pink-800 hover:bg-white transition duration-300 ease-in-out">
              {category.name}
            </button>
          </li>
        ))}
      </ul>
      {selectedCategory && (
        <div className="absolute left-0 top-full bg-white text-mainblue shadow-lg w-full p-4 grid grid-cols-4 gap-4 transition-all duration-300 ease-in-out">
          {subcategories.map((sub) => (
            <div key={sub.name} className="hover:bg-gray-200 p-2 rounded">
              <Link
                to={`/${categories.find((cat) => cat._id === selectedCategory)?.name}/${sub.name}`}
                className="block"
              >
                {sub.name}
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryNavbar;
