// src/components/Card.js
import React from "react";
import { useNavigate } from "react-router-dom";

const Card = ({ id, name, category, price, image, quantity }) => {
  console.log(id, name, category, price, image);
  const navigate = useNavigate();

  const handleProductClick = () => {
    navigate(`/viewproduct/${id}`);
  };

  return (
    <div
      className="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer"
      onClick={handleProductClick}
    >
      <img src={image} alt={name} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="text-lg font-semibold text-gray-800">{name}</h3>
        <p className="text-gray-500">{category}</p>
        <p className="text-mainpink font-bold">
          ₹{price * quantity}
          <span className="text-pink-400 font-medium text-sm"> (for {quantity} piece{quantity > 1 ? 's' : ''})</span>
        </p>
      </div>
    </div>
  );
};

export default Card;
