import React from 'react';
import { FaLaptopCode, FaMobileAlt, FaPencilRuler, FaTools } from 'react-icons/fa'; // Icons for services


const DesignWork = () => {
  return (
    <div className="bg-gray-50 min-h-screen p-8">
      <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg p-10">
        {/* Header Section */}
        <h1 className="text-4xl font-bold text-center mb-6 text-mainpink">Our Design Work</h1>
        <p className="text-lg text-center text-gray-600 mb-10">
          From concept to completion, we bring your ideas to life with cutting-edge design solutions. Discover the range of design work we offer for web, mobile, and branding.
        </p>

        {/* Image Section */}
        <div className="flex justify-center mb-10">
          <img
            src="https://th.bing.com/th/id/OIP.kMpOYOk21WeIhYtmglsFKwHaFj?rs=1&pid=ImgDetMain"
            alt="Design Work Example"
            className="rounded-lg shadow-lg"
          />
        </div>

        {/* Service Bullet Points */}
        <div className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-mainpink">Our Design Services Include:</h2>
          <ul className="list-disc pl-6 space-y-3 text-gray-700">
            <li>
              <FaLaptopCode className="inline-block text-mainpink mr-2" />
              Responsive Web Design – Modern, clean, and fully functional websites that work across all devices.
            </li>
            <li>
              <FaMobileAlt className="inline-block text-mainpink mr-2" />
              Mobile App Design – Engaging and user-friendly interfaces for both iOS and Android platforms.
            </li>
            <li>
              <FaPencilRuler className="inline-block text-mainpink mr-2" />
              Brand Identity Design – Logos, color schemes, and branding guidelines tailored to your business.
            </li>
            <li>
              <FaTools className="inline-block text-mainpink mr-2" />
              UX/UI Design – Intuitive and aesthetically pleasing user experiences for all types of digital products.
            </li>
          </ul>
        </div>

        {/* Call to Action */}
        <div className="text-center">
          <button className="bg-mainpink text-white font-semibold py-3 px-8 rounded-full shadow-lg hover:bg-blue-600 transition duration-300">
            Start Your Design Project Today!
          </button>
        </div>
      </div>
    </div>
  );
};

export default DesignWork;
