import React from "react";

const CopyrightMatters = () => {
  return (
    <div className="bg-pink-10 mt-20 mb-20 flex items-center justify-center">
      <div className="max-w-3xl mx-auto bg-white shadow-xl border-gray-300 rounded-lg p-8 md:p-12">
        <h1 className="text-3xl font-extrabold text-mainpink mb-4 text-center">
          Copyright Matters
        </h1>
        <hr className="border-t-2 border-lightpink mb-6" />
        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Copyright Policy
        </h2>
        <p className="text-base text-gray-700 leading-relaxed mb-4">
          All content on our website is protected by copyright and may not be reproduced, distributed, or used without our prior written permission. If you believe that your work has been copied in a way that constitutes copyright infringement, please notify us.
        </p>

        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Reporting Copyright Infringement
        </h2>
        <p className="text-base text-gray-700 leading-relaxed mb-4">
          To report a copyright infringement, please provide the following information:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li>Identification of the copyrighted work that you claim has been infringed.</li>
          <li>Identification of the material that is claimed to be infringing and information reasonably sufficient to allow us to locate the material.</li>
          <li>Your contact information, including your address, telephone number, and email address.</li>
          <li>A statement that you have a good faith belief that the disputed use is not authorized by the copyright owner.</li>
          <li>A statement that the information in your notice is accurate and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
        </ul>

        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Contact Information
        </h2>
        <p className="text-base text-gray-700 leading-relaxed mb-4">
          Please send your copyright infringement notice to: 
          <a href="mailto:copyright@Sastaprint.com" className="text-mainpink underline"> copyright@Sastaprint.com</a>.
        </p>
      </div>
    </div>
  );
};

export default CopyrightMatters;
