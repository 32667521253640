import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();

  // Retrieve cart items from localStorage on component mount
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(storedCart);
  }, []);

  // Remove item from cart
  const handleRemoveItem = (id) => {
    const updatedCart = cartItems.filter((item) => item.id !== id);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  // Calculate total cart value
  const getTotalPrice = () => {
    return cartItems
      .reduce((total, item) => total + parseFloat(item.totalPrice), 0)
      .toFixed(2);
  };

  // Handle checkout process
  const handleCheckout = () => {
    const token = localStorage.getItem('token');

    if (!token) {
      alert('You need to log in before proceeding to checkout.');
      navigate('/login');
      return;
    }

    const existingCartFinal = JSON.parse(localStorage.getItem('cartfinal')) || [];

    // Merge or update cartfinal with current cartItems
    const updatedCartFinal = [...existingCartFinal, ...cartItems];

    // Remove duplicates by filtering only unique items by id
    const uniqueCartFinal = updatedCartFinal.reduce((acc, item) => {
      if (!acc.some(cartItem => cartItem.id === item.id)) {
        acc.push(item);
      }
      return acc;
    }, []);

    // Update localStorage with the unique items
    localStorage.setItem('cartfinal', JSON.stringify(uniqueCartFinal));
    alert("Proceeding to Checkout. Your final cart has been updated.");
    navigate('/details');
  };

  return (
    <div className="max-w-4xl my-16 mx-auto p-4 sm:p-6">
      <h1 className="text-3xl font-bold mb-6 text-center sm:text-left">Your Cart</h1>

      {cartItems.length === 0 ? (
        <p className="text-gray-700 text-center">Your cart is empty.</p>
      ) : (
        <div className="space-y-6">
          {cartItems.map((item) => (
            <div
              key={item.id}
              className="flex flex-col sm:flex-row bg-white shadow-md rounded-lg overflow-hidden"
            >
              <img
                src={item.productImage}
                alt={item.productName}
                className="w-full sm:w-32 h-32 object-cover sm:h-auto"
              />
              <div className="flex flex-col p-4 flex-grow">
                <div className="flex justify-between items-start">
                  <h2 className="text-lg font-semibold">{item.productName}</h2>
                  <button
                    onClick={() => handleRemoveItem(item.id)}
                    className="text-red-500 hover:text-red-700 transition"
                  >
                    Remove
                  </button>
                </div>
                <p className="text-gray-500 mt-2">Die Type: {item.dieShape || 'N/A'}</p>
                <p className="text-gray-500">Quantity: {item.quantity}</p>
                <div className="mt-auto flex justify-between items-end">
                  <p className="text-lg font-bold">₹{item.price}</p>
                  <p className="text-lg font-bold">Total: ₹{item.totalPrice}</p>
                </div>
              </div>
            </div>
          ))}

          <div className="text-right mt-6">
            <p className="text-2xl font-bold mb-4">Total: ₹{getTotalPrice()}</p>
            <button
              onClick={handleCheckout}
              className="bg-mainblue text-white py-2 px-6 rounded-lg hover:bg-blue-700 transition"
            >
              Proceed to Checkout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
