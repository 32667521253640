import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {
  const [userDetails, setUserDetails] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [voucherCode, setVoucherCode] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [userId, setUserId] = useState('');
  const Navigate = useNavigate();
  useEffect(() => {
    // Fetch and decode user details from the token
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.user.id);
    }

    // Fetch user details from localStorage
    const storedUserDetails = JSON.parse(localStorage.getItem('userdetails')) || {};
    setUserDetails(storedUserDetails);

    // Fetch cart items from localStorage
    const storedCartItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(storedCartItems);

    // Calculate total price
    const price = storedCartItems.reduce((total, item) => total + parseFloat(item.totalPrice), 0);
    setTotalPrice(price.toFixed(2));
  }, []);

  const handleApplyVoucher = () => {
    // Implement voucher application logic
    if (voucherCode === 'DISCOUNT10') {
      const discountedPrice = (totalPrice * 0.9).toFixed(2);
      setTotalPrice(discountedPrice);
      alert('Voucher applied successfully!');
    } else {
      alert('Invalid voucher code.');
    }
  };

  const handleOrderNow = async () => {
    // Check if cart is empty
  if (cartItems.length === 0) {
    alert('Your cart is empty. Please add products to your cart before placing an order.');
    return; // Stop the function if the cart is empty
  }
    const paymentMethod = 'prepaid'; // Set default payment method to prepaid

    const orderDetails = {
      userId,
      userDetails,
      cartItems,
      totalPrice,
      voucherCode,
      paymentMethod,
      orderStatus: 'pending', // default order status
      orderDate: new Date(),
    };

    try {
      const response = await axios.post('https://api.payasvinimilk.com/api/orders', orderDetails);
      console.log('Order response:', response.data);

      // Redirect to payment page
      window.location.href = '/payment-page'; // Change this to your actual payment page URL
      alert('Order placed successfully!');
      // Clear the cart after placing the order
      localStorage.removeItem('cart');
    } catch (error) {
      console.error('Error placing order:', error);
      alert('Failed to place the order. Please try again.');
    }
  };

  const handleChoosePaymentMethod = () => {
    // Redirect to the payment methods page
    Navigate('/payment-page'); // Change this to your actual payment methods page URL
  };

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 flex flex-col sm:flex-row gap-6">
      {/* Left Section: User Details */}
      <div className="flex-grow">
        <div className="bg-white p-6 rounded-lg shadow-md mb-6 border border-gray-200">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Your Details</h2>
          <p className="text-gray-600"><strong>Name:</strong> {userDetails.name}</p>
          <p className="text-gray-600"><strong>Business Name:</strong> {userDetails.businessName}</p>
          <p className="text-gray-600"><strong>Mobile No:</strong> {userDetails.mobileNo}</p>
          <p className="text-gray-600"><strong>Email:</strong> {userDetails.email}</p>
          <p className="text-gray-600"><strong>Web Address:</strong> {userDetails.webAddress}</p>
          <p className="text-gray-600"><strong>Postal Address:</strong> {userDetails.postalAddress}</p>
        </div>
      </div>

      {/* Right Section: Cart Summary and Voucher */}
      <div className="w-full sm:w-1/3">
        <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Cart Summary</h2>
          {cartItems.length === 0 ? (
            <p className="text-gray-500 text-center">Your cart is empty.</p>
          ) : (
            <div>
              {cartItems.map((item) => (
                <div key={item.id} className="mb-4 border-b pb-4">
                  <div className="flex items-center justify-between text-gray-700">
                    <div className="flex items-center space-x-3">
                      <img src={item.productImage} alt={item.productName} className="w-12 h-12 object-cover rounded-md" />
                      <p className="font-semibold">{item.productName}</p>
                    </div>
                    <p className="text-gray-600">x {item.quantity}</p>
                  </div>

                  {/* Product Details Section */}
                  <div className="mt-2 text-sm text-gray-600">
                    {item.dieShape && (
                      <div><strong>Die Shape:</strong> {item.dieShape}</div>
                    )}
                    {item.paperType && (
                      <div><strong>Paper Type:</strong> {item.paperType}</div>
                    )}
                    {item.flapOpening && (
                      <div><strong>Flap Opening:</strong> {item.flapOpening}</div>
                    )}
                    {item.windowCutting && (
                      <div><strong>Window Cutting:</strong> {item.windowCutting}</div>
                    )}
                  </div>

                  {/* Total Price */}
                  <div className="flex justify-between items-center mt-2 text-gray-800 font-semibold">
                    <p>Amount:</p>
                    <p>₹{item.totalPrice}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="mt-6">
            <label className="block text-gray-700 font-semibold mb-2">Apply Voucher</label>
            <input
              type="text"
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter Voucher Code"
            />
            <button
              onClick={handleApplyVoucher}
              className="mt-2 w-full bg-mainblue text-white py-2 px-4 rounded-md hover:bg-blue-700 transition"
            >
              Apply Voucher
            </button>
          </div>

          {/* New Button for Payment Methods */}
          <div className="mt-4">
            <button
              onClick={handleChoosePaymentMethod}
              className="w-full bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400 transition"
            >
              Choose Payment Method
            </button>
          </div>
          <div className="mt-6">
            <p className="text-xl font-bold text-gray-800">Total: ₹{totalPrice}</p>
            <button
              onClick={handleOrderNow}
              className="w-full bg-mainpink text-white py-2 rounded-md hover:bg-pink-700 transition mt-4"
            >
              Order Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
