import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode"; // Correct import for jwt-decode

const Account = () => {
  const [activeSection, setActiveSection] = useState("orders");
  const [orders, setOrders] = useState([]);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login"); // Redirect to login if no token found
      return;
    }

    // Decode token to get user ID and name (assuming token contains user info)
    try {
      const decodedToken = jwtDecode(token);
      console.log(decodedToken);
      const userId = decodedToken.user.id;
      setUserName(decodedToken.name);

      // Fetch orders for the user
      const fetchOrders = async () => {
        try {
          const response = await fetch(`https://api.payasvinimilk.com/api/orders/user/${userId}`);
          const data = await response.json();
          if (data && Array.isArray(data.orders)) {
            setOrders(data.orders);
          } else {
            console.error("Unexpected data format:", data);
            setOrders([]);
          }
        } catch (error) {
          console.error("Error fetching orders:", error);
          setOrders([]);
        }
      };

      fetchOrders();
    } catch (error) {
      console.error("Error decoding token:", error);
      navigate("/login"); // Redirect to login if token is invalid
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.clear(); // Clear the token from local storage
    navigate("/login"); // Redirect to login page
  };

  const renderSection = () => {
    switch (activeSection) {
      case "orders":
        return (
          <div className="px-8">
            <h3 className="text-xl font-bold mb-4">Hello, {userName}!</h3>
            <div className="text-lg mb-4">Here you can see all your orders.</div>
            {orders.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {orders.map(order => (
                      <tr key={order._id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{order._id}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(order.orderDate).toLocaleDateString()}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">₹{order.totalPrice}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="mt-4 text-gray-600">No orders found.</p>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mt-20 mb-20 flex justify-center">
      <div className="max-w-6xl w-full mx-auto bg-white shadow-xl border-gray-300 rounded-lg p-8 md:p-12 flex flex-col md:flex-row">
        
        {/* Sidebar Navigation */}
        <div className="md:w-1/4 mb-8 md:mb-0 md:pr-8 border-r">
          <h2 className="text-2xl font-extrabold text-mainpink mb-4">Account</h2>
          <hr className="border-t-2 border-lightpink mb-6" />
          <ul className="space-y-4 text-gray-700">
            <li
              className={`cursor-pointer hover:text-mainpink ${activeSection === "orders" && "font-bold text-mainpink"}`}
              onClick={() => setActiveSection("orders")}
            >
              My Orders
            </li>
            <li
              className="cursor-pointer hover:text-red-600 text-red-500"
              onClick={handleLogout}
            >
              Logout
            </li>
          </ul>
        </div>

        {/* Main Content Area */}
        <div className="md:w-3/4">
          {renderSection()}
        </div>
      </div>
    </div>
  );
};

export default Account;
