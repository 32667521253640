import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Card from "../components/Card";
const ViewProducts = () => {
  const { subcatId, subcatName } = useParams(); // Ensure you pass both categoryId and subcatId
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`https://api.payasvinimilk.com/api/products/getProducts`);
        setProducts(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load products.');
        setLoading(false);
      }
    };

    fetchProducts();
  }, [ subcatId]);

  useEffect(() => {
    // Filter products based on categoryId and subcatId
    const filtered = products.filter(product => 
      product.subCategory === subcatId
    );
    setFilteredProducts(filtered);
  }, [products,  subcatId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="my-2">
      <div className="max-w-7xl mx-auto p-4">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          {subcatName}
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {filteredProducts.map((product) => (
            <Card
            key={product._id} // Add a unique key for each product
            name={product.name}
            id={product._id}
            category={product.category.name} // Assuming category is populated
            price={product.priceTiers[0].price}
            quantity={product.priceTiers[0].quantity}
            image={product.images[0]} // Display the first image
          />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewProducts;
