import React from 'react';
import { FaPenNib, FaPalette, FaRegLightbulb, FaRegStar } from 'react-icons/fa'; // Example React Icons


const LogoDesign = () => {
  return (
    <div className="bg-gray-50 min-h-screen p-8">
      <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg p-10">
        {/* Header Section */}
        <h1 className="text-4xl font-bold text-center mb-6 text-mainpink">Logo Design Services</h1>
        <p className="text-lg text-center text-gray-600 mb-10">
          Your logo is the face of your brand. We help you craft unique, memorable, and visually appealing logos that leave a lasting impression.
        </p>

        {/* Image Section */}
        <div className="flex justify-center mb-10">
          <img
            src="https://visme.co/blog/wp-content/uploads/2022/05/combination-mark-logo-examples.jpg"
            alt="Logo Design Example"
            className="rounded-lg shadow-lg"
          />
        </div>

        {/* Bullet Points Section */}
        <div className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-mainpink">Why Choose Our Logo Design Service?</h2>
          <ul className="list-disc pl-6 space-y-3 text-gray-700">
            <li>
              <FaPenNib className="inline-block text-mainpink mr-2" />
              Custom Designs Tailored to Your Brand's Identity
            </li>
            <li>
              <FaPalette className="inline-block text-mainpink mr-2" />
              Creative and Modern Aesthetics
            </li>
            <li>
              <FaRegLightbulb className="inline-block text-mainpink mr-2" />
              Innovative Ideas and Concepts
            </li>
            <li>
              <FaRegStar className="inline-block text-mainpink mr-2" />
              Professional Quality at Competitive Prices
            </li>
          </ul>
        </div>

        {/* Call to Action */}
        <div className="text-center">
          <button className="bg-mainpink text-white font-semibold py-3 px-8 rounded-full shadow-lg hover:bg-indigo-600 transition duration-300">
            Get Started with Your Logo Design!
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoDesign;
