import React from 'react';
import { FaLaptop, FaMobileAlt, FaPalette, FaRegChartBar } from 'react-icons/fa'; // Icons for services

const WebsiteDesign = () => {
  return (
    <div className="bg-gray-50 min-h-screen p-8">
      <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg p-10">
        {/* Header Section */}
        <h1 className="text-4xl font-bold text-center mb-6 text-mainpink">Website Design Services</h1>
        <p className="text-lg text-center text-gray-600 mb-10">
          Create beautiful, functional, and responsive websites that engage your audience and drive results. We design websites that reflect your brand’s identity and meet your business goals.
        </p>

        {/* Image Section */}
        <div className="flex justify-center mb-10">
          <img
            src="https://htmlburger.com/blog/wp-content/uploads/2023/04/modern-website-design-examples.jpg"
            alt="Website Design Example"
            className="rounded-lg shadow-lg"
          />
        </div>

        {/* Service Bullet Points */}
        <div className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-mainpink">Our Website Design Services Include:</h2>
          <ul className="list-disc pl-6 space-y-3 text-gray-700">
            <li>
              <FaLaptop className="inline-block text-mainpink mr-2" />
              Responsive Web Design – Websites that look great and perform well on all devices, from desktops to mobiles.
            </li>
            <li>
              <FaMobileAlt className="inline-block text-mainpink mr-2" />
              Mobile-First Design – Optimized user experiences for mobile devices, ensuring fast load times and ease of use.
            </li>
            <li>
              <FaPalette className="inline-block text-mainpink mr-2" />
              Custom Design & Branding – Tailored designs that reflect your brand’s identity, color scheme, and unique style.
            </li>
            <li>
              <FaRegChartBar className="inline-block text-mainpink mr-2" />
              SEO & Performance Optimization – Design that’s built for speed and optimized to rank well on search engines.
            </li>
          </ul>
        </div>

        {/* Call to Action */}
        <div className="text-center">
          <button className="bg-mainpink text-white font-semibold py-3 px-8 rounded-full shadow-lg hover:bg-green-600 transition duration-300">
            Start Your Website Design Project
          </button>
        </div>
      </div>
    </div>
  );
};

export default WebsiteDesign;
